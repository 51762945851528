@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"













.default-page

  // for Leitbild page, just to avoid having a separate template
  h2
    &.is-blue
      +font("bold")
      color: $blue
    &.is-orange
      +font("bold")
      color: $orange
    &.is-green
      +font("bold")
      color: $green
    &.is-purple
      +font("bold")
      color: $purple
